<template>
  <div class="large-screen-container flex flex-col">
    <Header title="河南智慧林业综合平台"></Header>
    <div class="body flex">
      <div class="content">
        <div class="left">
          <div class="left-top">
            <div class="left-top-title">
              河南省古树名木统计
            </div>
            <div class="section">
              <div class="hqxx-section">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon"></i>
                  <span class="hqxx-section-label-title">古树总数</span>
                </div>
                <div class="hqxx-sction-info">
                  <div class="hqxx-section-info-cell" v-for="(item,i) in drhqsArr" :key="i">
                    {{item != '-' ? item :''}}
                  </div>
                </div>
              </div>
              <div class="hqxx-section tree-section">
                <div class="tree-card">
                  <div class="tree-card-top">
                    名木（株）
                  </div>
                  <div class="tree-card-num">
                    {{woodNum?woodNum.preciousTrees:0}}
                  </div>
                </div>
                <div class="tree-card">
                  <div class="tree-card-top">
                    古树群（个）
                  </div>
                  <div class="tree-card-num">
                    {{woodNum?woodNum.oldTrees:0}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-medium">
            <div class="left-medium-title">
              河南省古树名木生长状态（株）
            </div>
            <div class="left-medium-wrap">
              <div class="list-top">
                <div style="flex:1;"></div>
                <div class="num">
                  数量
                </div>
                <div class="percent">
                  占比
                </div>
              </div>
              <div class="percent-list">
                <div class="percent-list-item" v-for="(item,i) in percentList" :key="i">
                  <div class="label">
                    {{item.name}}
                  </div>
                  <div class="percent-bar">
                    <div class="cur" :style="`width:${item.percent}%;`"></div>
                  </div>
                  <div class="num">
                    {{item.numText}}
                  </div>
                  <div class="percent">
                    {{item.percent || 0}}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-bottom">
            <div class="left-bottom-title">
              河南省古树名木权属（株）
            </div>
            <div class="left-bottom-wrap">
              <pie-chart :datas="pieDatas" title="权属分布数量"></pie-chart>
            </div>
          </div>
        </div>
        <div class="center">
          <MapChart ref="mapChartRef" @getCoordinate="getMapClickData" @checkMap="getMapData" />
        </div>
        <div class="right">
          <div class="right-top">
            <div class="right-top-title">
              河南省古树等级统计（株）
            </div>
            <div class="right-top-bottom">
               <div :class="['right-top-bottom-item', 'level-'+(3-index)]" v-for="(item, index) in levelList" :key="index">
                <div class="right-top-bottom-item-label">
                  <i class="level-icon"></i>
                  <span class="right-top-bottom-item-label-title">
                   {{item.title}}
                  </span>
                </div>
                <div class="right-top-bottom-item-num">
                  {{item.num}}
                </div>
              </div>
            </div>

          </div>

          <div class="right-medium">
            <div class="right-medium-title">
              河南省古树名木生长环境（株）
            </div>
            <div class="water-chart">
              <div class="liquidfill-wrap">
                <LiquidfillChart :datas="{value:  woodNum?woodNum.good:0, max: 30000}" :color="['#009D3C', '#009d3cad']"></LiquidfillChart>
                <div class="type-name">
                  良好
                </div>
              </div>
              <div class="liquidfill-wrap">
                <LiquidfillChart :datas="{value: woodNum?woodNum.difference:0, max: 30000}" :color="['#4986D5', '#4986d5b5']"></LiquidfillChart>
                <div class="type-name">
                  差
                </div>
              </div>
              <div class="liquidfill-wrap">
                <LiquidfillChart :datas="{value: woodNum?woodNum.range1:0, max: 30000}" :color="['#F28E26', '#f28e26bd']"></LiquidfillChart>
                <div class="type-name">
                  极差
                </div>
              </div>
            </div>
          </div>
          <div class="right-bottom">
            <div class="right-bottom-title">
              河南省古树名木城乡分布数量（株）
            </div>
            <div class="bar-chart">
              <pie-chart :datas="pieDatas2" title="城乡分布数量" :color="['#009D3C', '#4986D5']"></pie-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom ">
        <Navigation></Navigation>
      </div>
      <el-dialog
              title="古木名树详情"
              :visible.sync="dialogVisible"
              width="30%">
        <el-descriptions :column="2">
          <el-descriptions-item label="城市">{{detailData.city==null?'暂无':detailData.city}}</el-descriptions-item>
          <el-descriptions-item label="地区">{{detailData.county==null?'暂无':detailData.county}}</el-descriptions-item>
          <el-descriptions-item label="标题" :span="2">{{detailData.titel==null?'暂无':detailData.titel}}</el-descriptions-item>
          <el-descriptions-item label="图片" :span="2">
<!--            <img :src="OSS_URL+detailData.photo" alt="">-->
            <el-image v-if="detailData.photo"
                    style="width: 100px; height: 100px"
                    :src="OSS_URL+detailData.photo"
                    :preview-src-list="[OSS_URL+detailData.photo]">
            </el-image>
            <span v-else>暂无</span>
          </el-descriptions-item>
          <el-descriptions-item label="说明">{{detailData.descr==null?'暂无':detailData.descr}}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关闭</el-button>
  </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import PieChart from "./components/pie-chart";
import MapChart from "./components/map-chart";
import Header from '../../components/header'
import Navigation from '../../components/navigation'
import LiquidfillChart from './components/liquidfill-chart'
import { OSS_URL } from '@/config/env'
import {
  getWoodNum,
  listNoPage
} from '@/api/integratedPlatform'
export default {
  data() {
    return {
      dialogVisible:false,
      OSS_URL:OSS_URL,
      percentArr:[{
        name: '正常株',
        num: 25000
      },
      {
        name: '衰弱株',
        num:  4200
      },{
        name: '濒危株',
        num: 1022
      },{
        name: '死亡株',
        num: 36
      }],
      drhqs: '',
      pieDatas: [
          { value: 86, name: "个人" },
          { value: 185, name: "集体" },
          { value: 985, name: "国家" },
          { value: 52, name: "其他" },
      ],
      pieDatas2: [
          { value: 7021, name: "城市" },
          { value: 28700, name: "农村" },
      ],
      levelList:[
        {
          title: '三级古树',
          num: '20,528'
        },{
          title: '二级古树',
          num: '5,664',
        },{
          title: '一级古树',
          num: '3,915'
        },
      ],
      mapDatas: {

      },
      woodNum:{},
      woodList:[],
      detailData:{},
      mapData:{}
    };
  },
  components: {
    PieChart,
    MapChart,
    Header,
    Navigation,
    LiquidfillChart
  },
  computed: {
    drhqsArr(){
      return this.convertToArr(this.drhqs)
    },
    percentList(){
      const _arr = [...this.percentArr]
      _arr.sort((a,b)=>{return b.num - a.num})
      const max = _arr[0].num
      this.percentArr.forEach(item => {
        item.percent = ((item.num / max)*100).toFixed(2)
        item.numText =  (item.num+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
      return this.percentArr
    }
  },
  mounted(){
    this.getWoodNumFun()
    // this.listNoPageFun()
  },
  methods: {
    //地图下钻
    getMapData(e){
      if(e){
        this.mapData=e!=undefined?e:{}
        this.getWoodNumFun()
        this.listNoPageFun()
      }else{
        this.mapData={}
        this.getWoodNumFun()
      }

    },
    //触发点击地图事件使用
    getMapClickData(e){
      this.detailData=e
      this.dialogVisible=true
    },
    //古木名树详情
    listNoPageFun(){
      let data={
        city:this.mapData.name?this.mapData.name:'',
      }
      listNoPage(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.longitude,item.latitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)

        }
      })
    },
    //  古木树
    getWoodNumFun(){
      let data={
        pageNum:1,
        pageSize:10,
        city:this.mapData.name?this.mapData.name:'',
      }
      getWoodNum(data).then(res=>{
        if(res.code==200){
          this.woodNum=res.rows[0]?res.rows[0]:{}
          this.woodList=res.rows
          // this.$refs
          this.$refs.mapChartRef.setData(this.woodList)
          if(res.rows[0]){
            this.drhqs=this.woodNum.sumNum.toString()
            this.levelList[0].num=this.woodNum.level3
            this.levelList[1].num=this.woodNum.level2
            this.levelList[2].num=this.woodNum.level1
            this.percentArr[0].num=this.woodNum.normal
            this.percentArr[1].num=this.woodNum.weak
            this.percentArr[2].num=this.woodNum.endangered
            this.percentArr[3].num=this.woodNum.death
            this.pieDatas[0].value=this.woodNum.personal
            this.pieDatas[1].value=this.woodNum.collective
            this.pieDatas[2].value=this.woodNum.countryHave
            this.pieDatas[3].value=this.woodNum.others
            this.pieDatas2[0].value=this.woodNum.cityProper
            this.pieDatas2[1].value=this.woodNum.rural
          }
        }
      })
    },
    convertToArr(_str){
      const maxLen = 8
      let _arr = Array.apply(null, Array(10)).map(function() { return '-'; });
      const _beginIndex = maxLen - _str.length
      _arr.splice(_beginIndex)
      _arr = [..._arr, ..._str.split('')]
      return _arr
    },
  },
  mixins: [flexs],
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('../../assets/images/ls-bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 35px 39px 59px;
  position: relative;


  .body{
    height: calc(100% - 102px);
    width: calc(100% - 112px);
    position: absolute;
    left: 53px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .content{
      flex: 1;
      display: flex;
      .left{
				text-align: left;
        width: 437px;
        .left-top{
					height: 227px;
          background: url('./images/left-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-top-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
					.hqxx-section{
						margin-top: 13px;
						margin-left: 35px;
						margin-right: 20px;

						.hqxx-section-label{
							display: flex;
              align-items: center;
							.hqxx-section-label-icon{
								display: block;
								width: 24px;
								height: 24px;
								margin-right: 14px;
								background: url("./images/tree.png") center center no-repeat;
								background-size: 100% 100%;
							}
							.hqxx-section-label-title{
								flex: 1;
								height: 17px;
								line-height: 1;
								font-family: Microsoft YaHei;
								font-weight: 400;
								font-size: 16px;
								color: #FFFFFF;
								text-align: left;
							}
						}
            .hqxx-sction-info{
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              .hqxx-section-info-cell{
                width: 30px;
                height: 43px;
                background: #0D1732;
                border: 1px solid #2D80EC;
                line-height: 43px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 30px;
                color: #69FFFA;
                text-align: center;
              }
            }
            &.tree-section{
              display: flex;
              justify-content: space-between;
              .tree-card{
                width: 182px;
                height: 71px;
                background: url('./images/tree-card-bg.png') center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                text-align: center;
                .tree-card-top{
                  height: 17px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #FFFFFF;
                  line-height: 17px;
                }
                .tree-card-num{
                  height: 17px;
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 26px;
                  color: #69FFFA;
                  line-height: 17px;
                }
              }
            }
					}
				}
        .left-medium{
          margin-top: 8px;
					height: 335px;
          background: url('./images/left-bottom-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-medium-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .left-medium-wrap{
            padding: 7px 20px 0 23px;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            flex-direction: column;

            .list-top{
              display: flex;
              text-align: center;
              .num{
                width: 78px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                line-height: 17px;
              }
              .percent{
                width: 60px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                line-height: 17px;
              }
            }
            .percent-list-item{
              margin-top: 46px;
              display: flex;
              align-items: center;

              &:nth-of-type(2n){
                .percent-bar .cur{
                  height: 100%;
                  background-color: #4986D5;
                }
              }
              &:first-of-type{
                margin-top: 17px;
              }
              .label{
                width: 72px;
                height: 17px;
                line-height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
              }
              .percent-bar{
                flex: 1;
                height: 13px;
                background: #0C1D3F;
                border-radius: 2px;
                overflow: hidden;
                .cur{
                  height: 100%;
                  background-color: #7CDE7C;
                }
              }
              .num{
                width: 78px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                line-height: 17px;
                text-align: center;
              }
              .percent{
                width: 60px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                text-align: center;
                line-height: 17px;
              }
            }
          }
        }
        .left-bottom{
          margin-top: 8px;
					height: 335px;
          background: url('./images/left-bottom-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-bottom-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .left-bottom-wrap{
            padding: 7px 20px 0 23px;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
          }
        }
      }
      .right{
        width: 437px;
        margin-top: 4px;
        .right-top{
					height: 272px;
          background: url('./images/right-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .right-top-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .right-top-bottom{
            flex: 1;
            background: url('./images/right-top-bottom-bg.png') 45px 11px no-repeat;
            background-size: 263px 204px;
            .right-top-bottom-item{
              margin-top: 38px;
              padding-left: 127px;
              position: relative;
              &:first-of-type{
                margin-top: 74px;
              }
              &:last-of-type{
                margin-top: 34px;
              }
              .right-top-bottom-item-label{
                display: flex;
                align-items: center;
                .level-icon{
                  display: block;
                  width: 21px;
                  height: 21px;
                  background: url('./images/level-3.png') center center no-repeat;
                  background-size: 100% auto;
                  margin-right: 10px;
                }
                .right-top-bottom-item-label-title{
                  display: block;
                  flex: 1;
                  text-align: left;
                  height: 16px;
                  line-height: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  font-style: italic;
                }
              }
              .right-top-bottom-item-num{
                position: absolute;
                right: 33px;
                top: -14px;
                height: 23px;
                line-height: 23px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 24px;
                color: #0F6CFA;
                font-style: italic;
              }
              &.level-1{
                .right-top-bottom-item-num{
                  color: #2AA256;
                }
                .right-top-bottom-item-label .level-icon{
                  background: url('./images/level-1.png') center center no-repeat;
                  background-size: 100% auto;
                }
                .right-top-bottom-item-num{
                  right: 87px;
                }
              }
              &.level-2{
                .right-top-bottom-item-num{
                  color: #CE6E34;
                }
                .right-top-bottom-item-label .level-icon{
                  background: url('./images/level-2.png') center center no-repeat;
                  background-size: 100% auto;
                }
                .right-top-bottom-item-num{
                  right: 63px;
                }
              }
            }
          }
        }
        .right-medium{
          margin-top: 16px;
					height: 272px;
          background: url('./images/right-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .right-medium-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .water-chart{
            flex: 1;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .liquidfill-wrap{
              width: 90px;
              height: 115px;
              .type-name{
                margin-top: 20px;
                height: 15px;
                line-height: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #EBF5EC;
                line-height: 21px;
              }
            }
          }
        }
        .right-bottom{
          margin-top: 16px;
					height: 335px;
          background: url('./images/right-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .right-bottom-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .bar-chart{
            flex: 1;
          }
        }
      }
      .center{
        flex: 1;
      }
    }
    .bottom{
      height: 50px;
      margin-top: 13px;
    }
  }
}
</style>
