<template>
  <div class="map-chart-container">
    <div class="map" :id="id">
      <!-- 地图 -->
    </div>
    <div :class="`mark-point-box`"  v-show="show">

      <div class="mark-item-container" ref="box">
        <img src="../../images/close.png" @click="close" style="width:25px;height:25px;position: absolute;top:0px;right:20px;cursor: pointer" alt="">
        <div class="box-title">古树名木详情</div>
        <div class="mark-item" style="margin-top:20px;">
          <div class="mark-item-label">行政区划：</div>
          <div class="mark-item-val">
            {{tableData.city}}{{tableData.county}}
          </div>
        </div>
        <div class="mark-item">
          <div class="mark-item-label">标题：</div>
          <div class="mark-item-val">
            {{tableData.titel}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">图片：</div>
          <div class="mark-item-val">
            <el-image v-for="(item,index) in photoList" :key="index" v-show="photoList.length!=0"
                      style="width: 100px; height: 100px;margin:auto;margin-left:10px;"
                      :src="OSS_URL+item"
                      :preview-src-list="[OSS_URL+item]">
            </el-image>
            <span v-show="photoList.length==0">暂无</span>
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">说明：</div>
          <div class="mark-item-val">
            {{tableData.descr}}
          </div>
        </div>
<!--        <div class="mark-item">-->
<!--          <div class="mark-item-label">面积：</div>-->
<!--          <div class="mark-item-val">-->
<!--            {{tableData.area}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="mark-item">-->
<!--          <div class="mark-item-label">批复面积：</div>-->
<!--          <div class="mark-item-val">-->
<!--            {{tableData.approvedArea}}-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="mark-item">-->
<!--          <div class="mark-item-label">批复时间：</div>-->
<!--          <div class="mark-item-val">-->
<!--            {{tableData.approvedTime}}-->
<!--          </div>-->
<!--        </div>-->

        <!--        <div class="mark-item">-->
        <!--          <div class="mark-item-label">批准文号：</div>-->
        <!--          <div class="mark-item-val">-->
        <!--            林造批字【1992-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="mark-item">-->
        <!--          <div class="mark-item-label">调整批复文：</div>-->
        <!--          <div class="mark-item-val">-->
        <!--            - -->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import mapJson from "@/assets/mapJson/henansheng.json";
import cityMap from '@/assets/mapJson/city'
import point1 from '@/assets/images/point2.jpg'
import point2 from '@/assets/images/point1.jpg'
import { OSS_URL } from '@/config/env'
import mapBg from '@/assets/images/map-bg1.jpg'
// import {mapBg} from '@/assets/js/mapBg'

import { ScatterChart } from 'echarts';
export default {
  name: "MapChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "map-chart-" + this.$utils.uuid();
      },
    },
  },
  data() {

    return {
      OSS_URL:OSS_URL,
      show:false,
      tableData:{},
      mapData: [
        {
          name: "濮阳市",
          code: "pys",
          value: 20057,
          finish: 2000,
          doing: 234,
        },
        {
          name: "安阳市",
          code: "ays",
          value: 1547,
          finish: 12000,
          doing: 2344,
        },
        {
          name: "鹤壁市",
          code: "hbs",
          value: 31686,
          finish: 2200,
          doing: 555,
        },
        {
          name: "新乡市",
          code: "xxs",
          value: 6992,
          finish: 200,
          doing: 999,
        },
        {
          name: "焦作市",
          code: "jzs",
          value: 44045,
          finish: 1000,
          doing: 233344,
        },
        {
          name: "济源市",
          code: "jys",
          value: 40689,
          finish: 4000,
          doing: 24,
        },
        {
          name: "三门峡市",
          code: "smxs",
          value: 37659,
          finish: 20030,
          doing: 10,
        },
        {
          name: "洛阳市",
          code: "lys",
          value: 45180,
          finish: 202,
          doing: 2340,
        },
        {
          name: "郑州市",
          code: "zzs",
          value: 55204,
          finish: 20,
          doing: 234,
        },
        {
          name: "开封市",
          code: "kfs",
          value: 21900,
          finish: 123,
          doing: 234,
        },
        {
          name: "商丘市",
          code: "sqs",
          value: 4918,
          finish: 4000,
          doing: 234,
        },
        {
          name: "平顶山市",
          code: "pdss",
          value: 3881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "许昌市",
          code: "xcs",
          value: 2881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "周口市",
          code: "zks",
          value: 9881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "漯河市",
          code: "lhs",
          value: 1881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "南阳市",
          code: "nys",
          value: 881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "驻马店市",
          code: "zmds",
          value: 1981,
          finish: 9000,
          doing: 234,
        },
        {
          name: "信阳市",
          code: "xys",
          value: 2881,
          finish: 9000,
          doing: 234,
        },
      ],
      scatter3DArr:[
        // {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
        // {name: '周口市', value: [113.649653,33.620357], num: 100},
        // {name: '1', value: [114.649653,33.620357], num: 500},
        // {name: '2', value: [112.649653,33.620357], num: 500},
        // {name: '3', value: [112.649653,32.620357], num: 500},
        // {name: '5', value: [114.649653,33.620357], num: 500},
        // {name: '6', value: [113.649653,32.620357], num: 4444},
        // {name: '7', value: [111.649653,33.920357], num: 333},
      ],
      options:{
        tooltip: {
          show: false,
          enterable: true,
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          padding: 0,
          position: [10, 10], //浮窗位置
          formatter: (params)=>{
            if(params.seriesName=="test"){
              this.show = true
              this.tableData = params.data
              return this.$refs.box
            }
            // else{
            //   const _cur = this.woodList.find(item => item.city == params.name || item.county== params.name )|| ''
            //   return `${params.name}:${_cur.sumNum || 0}`
            // }
          },
          // color: '#fff', // 文字颜色
          // fontSize: '12px', // 文字大小
          // backgroundColor: this.show?'transparent':'rgba(5,4,41,0.79)',
          // borderColor: this.show?'transparent':'#4986D5',
          // borderWidth: this.show?0:1,
          // borderRadius: 5,
          // padding: this.show?0:10,
          // textStyle:{
          //   color:'#fff',
          //   fontSize: 16, // 文字大小
          // },
          // rich: {
          //   city: {
          //     color: '#D9EAF5',
          //     borderRadius: 15,
          //     padding: [0, 0, 0, 10],
          //     fontFamily: 'Microsoft YaHei',
          //     fontWeight: 400,
          //     fontSize: '16px',
          //     fontStyle: 'italic'
          //   },
          //   num: {
          //     color: '#2EED32',
          //     borderRadius: 15,
          //     padding: [0, 0, 0, 10],
          //     fontFamily: 'Microsoft YaHei',
          //     fontWeight: 400,
          //     fontSize: '16px',
          //     fontStyle: 'italic'
          //   }
          // }
        },
        geo: {
          map: 'henan',
          layoutCenter: ['50%', '50%'],
          layoutSize: '80%', // 地图大小，可以是百分比或者像素值
          selectedMode: 'single', // 地图高亮单选
          label: {
            show: true, // 是否显示名字
            color: '#fff', // 文字颜色
            fontSize: '0', // 文字大小
            formatter: (params)=>{
              const _cur = this.woodList.find(item => item.city == params.name|| item.county== params.name) || ''
              return `{city|${params.name}:}{num|${_cur.sumNum || 0}}`
            },
            backgroundColor: 'rgba(5,4,41,0.79)',
            borderColor: '#4986D5',
            borderWidth: 0,
            borderRadius: 5,
            padding: 3,
            rich: {
              city: {
                color: '#D9EAF5',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              },
              num: {
                color: '#2EED32',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              }
            }
          },
          realisticMaterial: {
            detailTexture: mapBg,  // 地图表面贴图
          },
          itemStyle:{
            color: 'rgba(24,144,255,1)', // 地图背景颜色
            borderWidth: 1, // 分界线wdith
            borderColor: '#fff', // 分界线颜色
            opacity: 0.82,
            borderType: "solid",
            areaColor: {
              type: "pattern",
              image: mapBg, //配置图片
              repeat: "repeat", //可选值repeat、no-repeat、repeat-x、repeat-y
            },
          },
          emphasis: {
            label: {
              show: false, // 是否显示高亮
              textStyle: {
                color: '#fff' // 高亮文字颜色
              }
            },
            itemStyle: {
              color: '#00baff', // 地图高亮颜色
              borderWidth: 0, // 分界线wdith
              opacity: 0,
              borderColor: '#6BECF5' // 分界线颜色
            }
          },

          left: '0',
          // top: '-20px',
          zoom: 1,
          // data: [],
          light: {
            main: {
              color: '#fff',
              intensity: 1.8,
              shadow: true,
              shadowQuality: 'high',
              alpha: 25, //
              beta: 20
            },
            ambient: {
              color: '#fff',
              intensity: 0.6
            }
          }
        },
        series: [
          {
            name: '地图',
            type: 'map',
            // registerMap注册的地图名称
            map: 'henan',
            // zoom: 1.2,
            zlevel: 0,
            layoutCenter: ['50%', '50%'],
            layoutSize: '80%', // 地图大小，可以是百分比或者像素值
            roam: false,
            label: {
              show: false,
              textStyle: {
                fontSize: 0,
                color: '#1d1d1d',
              },
            },
            itemStyle: {
              color: 'rgba(24,144,255,1)', // 地图背景颜色
              borderWidth: 0, // 分界线wdith
              borderColor: '#fff', // 分界线颜色
              opacity: 0,
              borderType: "dashed",
            },
            emphasis: {
              label: {
                show: false, // 是否显示高亮
                fontSize:0,
                textStyle: {
                  color: '#fff' // 高亮文字颜色
                }
              },
              itemStyle: {
                color: '#00baff', // 地图高亮颜色
                borderWidth: 0, // 分界线wdith
                opacity: 0.1,
                areaColor:'#00baff',
                borderColor: '#6BECF5' // 分界线颜色
              }
            },
            left: '0',
            zoom: 1,
            light: {
              main: {
                color: '#fff',
                intensity: 1,
                shadow: true,
                shadowQuality: 'high',
                alpha: 25, //
                beta: 20
              },
              ambient: {
                color: '#fff',
                intensity: 0.6
              }
            },
            data: [],
          },
          {
            name:'test',
            type: 'scatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            symbol: 'pin',
            symbolSize: 30,
            data: [],
            label: {
              show: false, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
              padding: 10,
            },
            itemStyle:{
              color:"#ff0"
            },
          },
        ],
      },

      option:{
        tooltip: {
          show: false,
        },
        geo3D:{
          type: 'map',
          name: '地图',
          // 相对于父容器比例
          center: ['50%', '50%'],
          selectedMode: 'single', // 地图高亮单选
          regionHeight: 0, // 地图高度
          map: 'henan',
          viewControl: {
            // 缩放大小，数值越大，地图越小
            distance: 145,
            // 上下倾斜角度
            alpha: 65,
            // 左右倾斜角度
            beta: 3,
            rotateSensitivity: 0,
            autoRotate:false,
            zoomSensitivity: false,
          },
          label: {
            show: false, // 是否显示名字
            color: '#fff', // 文字颜色
            fontSize: '12px', // 文字大小
            formatter: (params)=>{
              const _cur = this.woodList.find(item => item.city == params.name) || ''
              return `{city|${params.name}:}{num|${_cur.sumNum || 0}}`
            },
            backgroundColor: 'rgba(5,4,41,0.79)',
            borderColor: '#4986D5',
            borderWidth: 1,
            borderRadius: 5,
            padding: 10,
            rich: {
              city: {
                color: '#D9EAF5',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              },
              num: {
                color: '#2EED32',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              }
            }
          },
          shading: "realistic",
          realisticMaterial: {
            detailTexture: mapBg,  // 地图表面贴图
          },
          itemStyle: {
            color: 'rgba(24,144,255,1)', // 地图背景颜色
            borderWidth: 1, // 分界线wdith
            borderColor: '#fff', // 分界线颜色
            opacity: 0.92,
            borderType: "dashed",
          },
          emphasis: {
            label: {
              show: true, // 是否显示高亮
              textStyle: {
                color: '#fff' // 高亮文字颜色
              }
            },
            itemStyle: {
              color: '#00baff', // 地图高亮颜色
              borderWidth: 10, // 分界线wdith
              borderColor: '#6BECF5' // 分界线颜色
            }
          },
          left: '0',
          // top: '-20px',
          zoom: 1,
          // data: [],
          light: {
            main: {
              color: '#fff',
              intensity: 1.8,
              shadow: true,
              shadowQuality: 'high',
              alpha: 25, //
              beta: 20
            },
            ambient: {
              color: '#fff',
              intensity: 0.6
            }
          }
        },
        series: [
          {
            zlevel: 5,
            type: 'map3D',
            name: '地图',
            // 相对于父容器比例
            center: ['50%', '50%'],
            selectedMode: 'single', // 地图高亮单选
            regionHeight: 6, // 地图高度
            map: 'henan',
            viewControl: {
              // 缩放大小，数值越大，地图越小
              distance: 145,
              // 上下倾斜角度
              alpha: 65,
              // 左右倾斜角度
              beta: 3,
              rotateSensitivity: 0,
              autoRotate:false,
              zoomSensitivity: false,
            },
            label: {
              show: false, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
              distance: 50,
            },
            itemStyle: {
              color: 'rgba(24,144,255,1)', // 地图背景颜色
              borderWidth: 0, // 分界线wdith
              borderColor: '#fff', // 分界线颜色
              opacity: 0,
              borderType: "dashed",
            },
            emphasis: {
              label: {
                show: false, // 是否显示高亮
                textStyle: {
                  color: '#fff' // 高亮文字颜色
                }
              },
              itemStyle: {
                color: '#00baff', // 地图高亮颜色
                borderWidth: 0, // 分界线wdith
                opacity: 0,
                borderColor: '#6BECF5' // 分界线颜色
              }
            },
            left: '0',
            // top: '-20px',
            zoom: 1,
            // data: [],
            light: {
              main: {
                color: '#fff',
                intensity: 1,
                shadow: true,
                shadowQuality: 'high',
                alpha: 25, //
                beta: 20
              },
              ambient: {
                color: '#fff',
                intensity: 0.6
              }
            }
          },
          {
            // tooltip: {
            //   trigger: 'item', // 触发类型，这里是数据项图形触发
            //   // 你可以自定义 tooltip 的显示内容
            //   formatter: function (params) {
            //     return '数据点名称: ' + params.name + '<br/>数据点值: ' + params.value;
            //   }
            // },
            zlevel: 1000000,
            geo3DIndex: -1,
            silent: false,
            itemStyle: {
              opacity: 1,
              width: 1
            },
            name:'test',
            type: 'scatter3D',
            coordinateSystem: 'geo3D', // 指定坐标系为 3D 地理坐标系
            data: [],
            // symbol:'image://'+point1,
            symbolSize:15,
            // symbol:(value,params)=>{
            //   console.log(value,params,'00000')
            //   //value就是
            //   // if(params.name == '南京市'){
            //   //   return 'image://data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7'
            //   // }
            // },
            label: {
              show: false, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
              // formatter: ({data})=>{
              //   if(data.num > 10000) {
              //     return `{point2|}`
              //   }
              //   return `{point1|}`
              // },
              padding: 10,
            // rich: {
            //   point1: {
            //     width: 40,
            //     height: 40,
            //     backgroundColor:{
            //       image:point1
            //     } ,
            //     borderRadius: 15,
            //     padding: [0, 0, 0, 10],
            //     fontFamily: 'Microsoft YaHei',
            //     fontWeight: 400,
            //     fontSize: '16px',
            //     fontStyle: 'italic'
            //   },
            //   point2: {
            //     width: 40,
            //     height: 40,
            //     backgroundColor:{
            //       image:point2
            //     } ,
            //     borderRadius: 15,
            //     padding: [0, 0, 0, 10],
            //     fontFamily: 'Microsoft YaHei',
            //     fontWeight: 400,
            //     fontSize: '16px',
            //     fontStyle: 'italic'
            //   }
            // }
          },
            itemStyle:{
              color:"#ff0"
            },
            emphasis: {
                label: {show: false},
              itemStyle:{
                  color:"#f00"
              }
            },
            // 其他散点图配置
          },
        ]
      },
      chart: null,
      pointer: 0,
      timer: null,
      woodList:[],
      photoList:[]
    };
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {

    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.$echarts.registerMap("henan", mapJson);
    if(cityMap){
      Object.keys(cityMap).forEach(key => {
        this.$echarts.registerMap(key, cityMap[key]);
      })
    }
      this.draw({
            data: this.mapData,
            // scatter3DArr: this.scatter3DArr
          })
    });
  },
  methods: {
    close(){
      this.show=false
    },
    reInit(scatter3DArr){
      this.scatter3DArr=scatter3DArr
      this.chart.dispose();
      const chartDom = document.getElementById(this.id);
      this.chart = this.$echarts.init(chartDom);
      this.draw({
        data: this.mapData,
        scatter3DArr
      })
    },
    setData(e){
      this.woodList=e?e:[]
      this.draw(
        {
          data: this.mapData,
          // scatter3DArr: this.scatter3DArr
        }
      )
    },
    initEvent() {
      this.chart.off('click')
      this.chart.on('click', {seriesName: 'test'}, ({data})=> {
        console.log(data,'000')
        if(data){
          this.show=true
          this.tableData=data
          let photoList=this.tableData.photo.split(',')
          this.photoList=photoList
        }
      });
      this.chart.on('click', {seriesName: '地图'}, ({data})=> {
        if(data){
          this.options.series[0].map = data.code
          this.options.series[0].data = []
          this.options.geo.map = data.code
          this.chart.dispose();
          const chartDom = document.getElementById(this.id);
          this.chart = this.$echarts.init(chartDom);
          this.draw({

          })
          this.$emit('checkMap',data)
        }else {
          if (this.options.series[0].map != 'henan') {
            this.options.series[0].map = 'henan'
            this.options.geo.map = 'henan'
            this.chart.dispose();
            const chartDom = document.getElementById(this.id);
            this.chart = this.$echarts.init(chartDom);
            this.draw({
              data: this.mapData,
              // scatter3DArr: this.scatter3DArr
            })
          }
          this.$emit('checkMap',null)
        }

        // series name 为 'uuu' 的系列中的图形元素被 'mouseover' 时，此方法被回调。
      });
      //  this.chart.getZr().on('click', params => {
      //    console.log(params,'zhangahah')
      //   if(params.target) {
      //     //画布非空白区
      //     return;
      //   } else {
      //     if(this.options.series[0].map != 'henan'){
      //       this.$emit('checkMap',{})
      //       this.options.series[0].map = 'henan'
      //       this.options.geo.map = 'henan'
      //       this.chart.dispose();
      //       const chartDom = document.getElementById(this.id);
      //       this.chart = this.$echarts.init(chartDom);
      //       this.draw({
      //         data: this.mapData,
      //         scatter3DArr: this.scatter3DArr
      //       })
      //     }
      //   }
      //
      // });
    },
    draw(params = {}) {
      const {data =[], scatter3DArr = []} = params
      this.options.series[0].data = data;
      this.options.series[1].data = scatter3DArr
      this.chart.setOption(this.options, true);
      this.initEvent()
    },
  },
};
</script>
<style lang="scss" scoped>
.map-chart-container,
.map {
  position: relative;
  width: 100%;
  height: 100%;
}
.title{
  width: 149px;
  height: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 20px;
  color: #E7F8FB;
  line-height: 20px;
  position: absolute;
  left: 62px;
  top: 30px;
  padding-left: 13px;
  white-space: nowrap;
  &::before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 5px;
    height: 5px;
    background: #6DFF58;
    border-radius: 50%;
  }

}
@keyframes cycle
{
    from {
      width: 15px;
      height: 15px;
    }
    to {
      width: 50px;
      height: 50px;
    }
}
.map-chart-container {
  position: relative;
}
// 标记浮窗显示的内容
.mark-item-container {
  // left: 420px;
  // top: 115px;
  // position: ;
  position: fixed;
  top:20%;
  left:calc((100vw - 850px) / 2);
  width: 850px;
  /*min-height: 240px;*/
  background: url('../../../investigationMonitor/images/dialog-bg.png') center center no-repeat;
  background-size: 100% 100%;
  display: block;
  padding: 0 10px 20px 10px;
  .box-title{
    line-height: 1;
    text-align: left;
    /*position: absolute;*/
    /*left: 20px;*/
    /*top: 18px;*/
    width: 100%;
    height: 15px;
    line-height:32px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    /*line-height: 15px;*/
    white-space: nowrap;
  }
  .mark-item {
    margin-top: 5px;
    position: relative;
    width: 100%;
    /*height: 40px;*/
    /*line-height: 40px;*/
    display: flex;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: rgba(21,46,131,0.75) !important;
    /*padding: 0 10px 0 13px;*/
    padding:10px 5px;
    .mark-item-label{
      text-align: left;
      width: 100px;
      white-space: nowrap;
      color: #fff;
      z-index: 2;
      position: relative;
    }
    .mark-item-val{
      flex: 1;
      color: #fff;
      z-index: 2;
      position: relative;
      text-align: right;
      white-space: normal;
    }
    /*&::after {*/
    /*  content: "";*/
    /*  display: block;*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*  background: #152E83;*/
    /*  opacity: 0.75;*/
    /*  position: absolute;*/
    /*  margin: auto;*/
    /*  top: 0;*/
    /*  left: 0;*/
    /*  z-index: 0;*/
    /*}*/
  }

  .left-top-corner,
  .left-bottom-corner,
  .right-top-corner,
  .right-bottom-corner {
    width: 11px;
    height: 11px;
    display: block;
    background-color: transparent;
    position: absolute;
    border: 1px solid #00e5ff;
  }
  .left-top-corner {
    left: 0;
    top: 0;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  .left-bottom-corner {
    left: 0;
    bottom: 0;
    border-right-color: transparent;
    border-top-color: transparent;
  }
  .right-top-corner {
    right: 0;
    top: 0;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  .right-bottom-corner {
    right: 0;
    bottom: 0;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
</style>
