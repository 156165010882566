const env = process.env
let baseUrl = ''
//素材相关参数
let OSS_URL = "http://192.168.1.96:8091/";
if (env.NODE_ENV === 'development') {
    baseUrl = 'http://192.168.1.26:9999'
    OSS_URL = "http://192.168.1.96:8091"
} else if (env.NODE_ENV === 'production') {
    baseUrl="http://114.115.153.83"//测试
    OSS_URL = "http://114.115.153.83/api"//测试
}
export {
    baseUrl,
    env,
    OSS_URL,
}
