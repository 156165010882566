<template>
  <div class="liquidfill-chart-container" :id="id">
    <!-- 水滴图 -->
  </div>
</template>
<script>
import "echarts-liquidfill/src/liquidFill.js";
export default {
  name: "liquidfillChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "liquidfill-chart-" + this.$utils.uuid();
      },
    },
    color: {
      type: Array,
    },
    datas:{
      type: Object
    }
  },
  data() {
    return {
      option: {
          series: [
              {
                  type: "liquidFill", //水位图
                  radius: "90%", //显示比例
                  center: ["50%", "50%"], //中心点
                  amplitude: '8%', //水波振幅
                  backgroundStyle: {
                      borderWidth: 0, //外边框
                      borderColor: "#33c8ff", //边框颜色
                      color: "#1E7AD9" //边框内部填充部分颜色
                  },
                  // color: ["#4db6ac"], //波浪颜色
                  //这是设置波浪渐变色
                  itemStyle: {
                      normal: {
                          color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                  offset: 0,
                                  color: '#e744ff',
                              },
                              {
                                  offset: 1,
                                  color: '#33c8ff',
                              },
                          ]),
                      },
                  },
                  label: {
                      //标签设置
                      position: ["50%", "50%"],
                      formatter: ()=>{
                          return this.datas.value
                      }, //显示文本,
                      fontSize: "20px", //文本字号,
                      color: '#EBF5EC'
                  },
                  outline: {
                      show: false //最外层边框显示控制
                  }
              }
          ]
      },
      liquidfillChart: null,
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.liquidfillChart = this.$echarts.init(chartDom);
    this.draw();
  },
  watch: {
    datas: {
      handler: function (val) {
        if (val) {
          this.draw();
        }
      },
      deep: true,
    },
  },
  methods: {
    draw() {
      this.option.series[0].data = [(this.datas.value / this.datas.max).toFixed(2), (this.datas.value / this.datas.max).toFixed(2)]
      this.option.series[0].itemStyle.normal.color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                        {
                                                            offset: 0,
                                                            color: this.color[0],
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: this.color[1],
                                                        },
                                                    ])
      this.liquidfillChart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.liquidfill-chart-container {
  width: 100%;
  height: 90px;
}
</style>